html {
  --lcolor: #d4bfff;
  --scolor: #874aff;
  --bcolor: #3a2d53;
  --borderColor: #615280;
  --borderSize: 3px;
  --whiteColor: #f4efff;
}

body {
  font-family: 'Raleway', sans-serif;
  font-variant: initial;
  margin: 0;
}

.separador {
  padding: 1rem 0 !important;
  text-align: center;
  border-bottom: var(--borderSize) solid var(--borderColor);
  border-top: var(--borderSize) solid var(--borderColor);
  background-color: var(--lcolor) !important;

  h1, h2, h3, h4, h5, h6 {
    color: var(--bcolor);
    margin: 0;
  }
}

.navbar {
  padding: 2rem 1rem;
  font-size: 1.1rem;
  background-color: var(--lcolor);
  border-bottom: var(--borderSize) solid var(--borderColor);

  .navbar-brand {
    font-family: "Playwrite CU", cursive;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.1rem;
  }

  .btn {
    margin-left: 1rem;
    font-size: 1.1rem;
  }
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  .presentation {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 500px;
    padding: 2rem 1rem;
    border-radius: 5px;
    background-color: var(--whiteColor) !important;
    border: var(--borderSize) solid var(--borderColor);
    border-radius: 5px;
  }

  .mainPicture {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 250px;

    img {
      border: var(--borderSize) solid var(--borderColor);
      border-radius: 5px;
      max-width: 250px;
      aspect-ratio: 1/1;
    }
  }
}

.contato {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 5rem 0;
  flex-direction: column;


  .contato-title {
    color: var(--whiteColor);
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .contato-box {
    padding: 3rem;
    text-align: center;
    border-radius: 5px;
    border: var(--borderSize) solid var(--borderColor);
    background-color: var(--lcolor) !important;
    border-radius: 5px;
  }
}

.split {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.mainPicture {
  width: 80%;
  max-width: 250px;
  aspect-ratio: 1/1;
}

.footer {
  background-color: var(--lcolor) !important;
  text-align: center;
  padding: 1rem 0;
  border-top: var(--borderSize) solid var(--borderColor) !important;
}

.cardBox {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;


  .card {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 500px;
    flex-direction: column;
    align-items: center;
    background-color: var(--whiteColor) !important;
    border: var(--borderSize) solid var(--lcolor);
    border-radius: 5px;

    .card-title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}